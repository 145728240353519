import React, { useEffect, useState } from "react";
import { UserMessage } from "./components/UserMessage";
import { ChatMessage } from "./components/ChatMessage";
import { ChatInput } from "./components/ChatInput";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import Messages from "../../utils/api/Messages";
import { ReactComponent as LogoBig } from "../../assets/logoBig.svg";
import { toast } from "react-toastify";
import { IMessage } from "../../utils/types/chat";

export const ChatPage = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const params = useParams();

  const getMessages = async () => {
    const messagesResponse = await Messages.getChatMessages(
      params.id as string
    );
    if (messagesResponse.data) {
      console.log(messagesResponse.data, "mes");
      setMessages(messagesResponse.data.reverse());
    }
  };

  useEffect(() => {
    getMessages();
  }, [params.id]);

  const sendMessageMutation = useMutation({
    mutationFn: (content: string) => {
      return Messages.sendMessage(params.id as string, content);
    },
    onMutate: () => {
      setInputValue("");
    },
    onSuccess: () => {
      getMessages();
    },
    onError: () => {
      toast.error("Something went wrong, try again later");
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!inputValue.length || sendMessageMutation.isLoading) return;

    if (inputValue.length <= 5) {
      toast.error("Message should be longer than 5 symbols!");
      return;
    }

    sendMessageMutation.mutate(inputValue);

    setMessages((prev) => [
      {
        role: "user",
        id: Date.now().toString(),
        content: inputValue,
        createdAt: Date.now().toString(),
        chatId: params.id as string,
      },
      ...prev,
    ]);
  };

  const handleSuggestionsClick = (suggestion: {
    title: string;
    description: string;
  }) => {
    sendMessageMutation.mutate(suggestion.title + " " + suggestion.description);
    setMessages((prev) => [
      {
        role: "user",
        id: Date.now().toString(),
        content: suggestion.title + " " + suggestion.description,
        createdAt: Date.now().toString(),
        chatId: params.id as string,
      },
      ...prev,
    ]);
  };

  const suggestions = [
    {
      title: "Use lines from",
      description: "the Simpsons to write my grandpa a birthday card.",
    },
    {
      title: "What episode of",
      description: "Modern Family has the most profanity? Why?",
    },
    {
      title: "What are the time codes for 10 times in",
      description:
        'a romantic movie or TV show when a character says "Chicago" and another 10 times when they say "the Windy City".',
    },
    {
      title: "Create authentic dialogue based on their media",
      description: "The Doctor meets Hermione.",
    },
  ];

  return (
    <div className="flex flex-col justify-between flex-1 max-h-screen">
      {messages.length ? (
        <div className="flex flex-col-reverse flex-1 gap-6 px-4 py-4 mb-2 overflow-y-scroll no-scrollbar md:px-28 md:pt-20 md:pb-11 mobile-chat">
          {messages.map((message) => (
            <>
              {message.role === "user" ? (
                <UserMessage key={message.id} message={message.content} />
              ) : (
                <ChatMessage key={message.id} message={message.content} />
              )}
            </>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center h-full px-4 py-4 md:px-28 md:pt-20 md:pb-11 md:justify-end">
          <LogoBig className="w-full max-w-full" />
          <div className="flex flex-wrap gap-5">
            {suggestions.map((suggestion, index) => (
              <div
                onClick={() => handleSuggestionsClick(suggestion)}
                className={`bg-dark py-6 px-5 rounded-2xl flex-1 md:basis-[45%] cursor-pointer ${index > 1 ? "lg:block hidden" : ""}`}
                key={suggestion.title}>
                <h5 className="text-lg font-bold text-white font-rokkitt">
                  {suggestion.title}
                </h5>
                <p className="text-sm font-medium font-nunito text-gray">
                  {suggestion.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col w-full px-4 mb-2 md:px-28">
        <ChatInput
          value={inputValue}
          setValue={setInputValue}
          onSubmit={onSubmit}
          isLoading={sendMessageMutation.isLoading}
        />
        <p className="mt-2 text-xs font-medium text-center font-nunito text-gray">
          Disclaimer about how it’s very accurate but not perfect, Check the
          source material you are going to cite.
        </p>
      </div>
    </div>
  );
};
