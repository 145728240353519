import React from "react";
import { ReactComponent as AssistantLogo } from "../../../../assets/assistantLogo.svg";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DownloadFileButton from "../../../../components/DownloadFileButton/DownloadFileButton";

interface IMessageProps {
  message: string;
}

export const ChatMessage: React.FC<IMessageProps> = ({ message }) => {
  return (
    <div className="flex flex-row items-start justify-start gap-2 px-5 py-2 bg-dark rounded-2xl">
      <AssistantLogo width={42} height={42} className="shrink-0" />
      <div className="text-sm font-medium text-white font-nunito">
        <Markdown
          urlTransform={(url) => url}
          remarkPlugins={[remarkGfm]}
          components={{
            table: ({ children, ...props }) => (
              <table className="my-4" {...props}>
                {children}
              </table>
            ),
            a: ({ children, href, ...props }) => {
              if (href?.startsWith("http"))
                return (
                  <a href={href} {...props}>
                    {children}
                  </a>
                );

              return (
                <DownloadFileButton fileId={href || ""}>
                  {children}
                </DownloadFileButton>
              );
            },
            code: ({ children, ...props }) => (
              <code className="my-4" {...props}>
                {children}
              </code>
            ),
          }}>
          {message}
        </Markdown>
      </div>
    </div>
  );
};
