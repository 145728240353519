import React from "react";
import { api } from "../../utils/api/api";
import { toast } from "react-toastify";
import { cn, downloadBlob } from "../../utils/general";

interface DownloadFileButtonProps {
  fileId: string;
  children: React.ReactNode;
}

const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  fileId,
  children,
}) => {
  const [isDownloadingFile, setIsDownloadingFile] =
    React.useState<boolean>(false);

  const handleDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDownloadingFile(true);

    const toastId = toast.loading("Downloading the file...");

    api
      .get(`/file/download/${fileId}`, { responseType: "blob" })
      .then((response) => {
        downloadBlob(response);

        toast.update(toastId, {
          render: "Downloaded!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        setIsDownloadingFile(false);
      })
      .catch(() => {
        toast.update(toastId, {
          render: "Error while downloading the file.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });

        setIsDownloadingFile(false);
      });
  };

  return (
    <a
      href="#"
      onClick={handleDownload}
      className={cn("cursor-pointer hover:underline", {
        "opacity-30 cursor-default": isDownloadingFile,
      })}>
      {children}
    </a>
  );
};

export default DownloadFileButton;
