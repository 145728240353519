import { api } from "./api";

const getChatMessages = async (id: string) => {
  const response = await api.get(`/message/${id}`);
  return response.data;
};

const sendMessage = async (id: string, content: string) => {
  const response = await api.post(`/message/${id}`, { content });
  return response.data;
};

export default { getChatMessages, sendMessage };
